import React, { useState } from 'react';
import { motion } from 'framer-motion';
// import * as obj from 'react';
// const {useState} = obj ;

const ContactSection = () => {
    // react app will keep eye on formdata
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    function sendEmail() {
        const bodyMsg = `Email: ${formData.email} <br>Name: ${formData.name} <br>Message: ${formData.message} <br>`;
        console.log(bodyMsg);

        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: "prince.rjb839@gmail.com",
            Password: "1FBBF7AE49BA37F481BF6B55DF5E0D217B35",
            To: 'Prince.rjb839@gmail.com',
            From: "Prince.rjb839@gmail.com",
            Subject: "New Contact Message",
            Body: bodyMsg,
        }).then((message) => {
            alert("Thank you for reaching out! I'll review your message and get back to you soon.");
            // reset form data 
            setFormData({
                name: '',
                email: '',
                message: '',
            });
        }).catch((error) => {
            console.error("Failed to send email:", error);
            alert("There was an error sending your message. Please try again.");
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail();
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <motion.div animate={{  y:-100 } } 
        transition={{ duration: 0.5 }} 
        // whileInView={{ opacity: 1 }}
          variants={{
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1, // Delay between child animations
        delay: 0.2,
      }
    }
  }}

         name="Contact" className="container mx-auto mt-20 p-4 lg:h-[100vh]">
            <h2 className="text-3xl font-bold text-center mb-20 hidden lg:block ">Contact Me</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Left side: Contact Information */}
                <div className="flex flex-col justify-center gap-y-6 p-6 rounded-lg">
                    <h3 className="text-2xl font-semibold mb-4 underline underline-offset-4">Contact Info</h3>
                    <p className="text-gray-600 mb-2 text-xl">
                        <strong>Address:</strong> Rajbiraj, Nepal
                    </p>
                    <p className="text-gray-600 mb-2 text-xl">
                        <strong>Email:</strong><a href="mailto:Prince.rjb839@gmail.com"> Prince.rjb839@gmail.com</a>
                    </p>
                    <p className="text-gray-600 mb-2 text-xl">
                        <strong>Phone:</strong> +91-9334326203
                    </p>
                </div>

                {/* Right side: Contact Form */}
                <div className="bg-[#414141] hover:-rotate-1 transition duration-700 ease-in-out p-6 rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <input
                                placeholder='Name'
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="mt-1 p-2 block w-full bg-[#8e8b8b8f] rounded-md outline-none sm:text-sm"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <input
                                placeholder='Email'
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 p-2 block w-full bg-[#8e8b8b8f] rounded-md outline-none sm:text-sm"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <textarea
                                placeholder='Message'
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                className="resize-none mt-1 p-2 block w-full bg-[#8e8b8b8f] rounded-md outline-none sm:text-sm"
                                required
                            ></textarea>
                        </div>

                        <button
                            type="submit"
                            className="bg-[#feb273] hover:bg-[#feb27381] text-white px-4 py-2 rounded-md w-full"
                        >
                            Send Enquiry
                        </button>
                    </form>
                </div>
            </div>
        </motion.div>
    );
};

export default ContactSection;
