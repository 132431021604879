import { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-scroll";
import logoImage from "../../image/pulling.png";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 64 64"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#1c1f1f" />
    <text
      x="50%"
      y="50%"
      fill="#bcd7d7"
      alignmentBaseline="middle"
      dominantBaseline="middle"
      dy=".1em"
      fontSize={28}
      fontWeight={400}
      textAnchor="middle"
    >
      {"PR"}
    </text>
  </svg>
);

const Nav = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };

  const renderContent = () => (
    <div  className="lg:hidden z-50 block p-2 absolute w-50 top-20  right-4 bg-[#2a2e33] rounded-lg">
      <ul className="text-center text-sm px-20">
        <Link spy={true} smooth={true} to="Home">
          <li className="text-center my-2 py-2 border-b border-slate-800 hover:bg-[#f7f7f7] hover:text-black hover:rounded">
            Home
          </li>
        </Link>
        <Link spy={true} smooth={true} to="About">
          <li className="my-2 py-2 border-b border-slate-800 hover:bg-[#f7f7f7] hover:text-black  hover:rounded">
            About
          </li>
        </Link>
        <Link spy={true} smooth={true} to="Skills">
          <li className="my-2 py-2 border-b border-slate-800 hover:bg-[#f7f7f7] hover:text-black  hover:rounded">
            Skills
          </li>
        </Link>
        <Link spy={true} smooth={true} to="Project">
          <li className="my-2 py-2 border-b border-slate-800 hover:bg-[#f7f7f7] hover:text-black  hover:rounded">
            Projects
          </li>
        </Link>
        <Link spy={true} smooth={true} to="Contact">
          <li className="my-2 py-2 border-b border-slate-800 hover:bg-[#f7f7f7] hover:text-black  hover:rounded">
            Contact
          </li>
        </Link>
      </ul>
    </div>
  );

  return (
    <nav>
      <div name="Top" className="h-10vh flex justify-between text-[#bcd7d7] lg:py-5 px-20 py-4 lg:mx-40  sm:px-6 ">
        <div className="flex items-center flex-1 hover:animate-move ">
            <img src={logoImage} className="w-14 " alt="" />
          <span className="rounded-full border border-[#bcd7d7] ">
            <SvgComponent />
          </span>
        </div>

        <div className="hidden lg:flex flex-1 items-center justify-end font-normal">
          <ul className="flex gap-8 mr-16 text-[18px]">
            <Link spy={true} smooth={true} to="Home">
              <li className="hover:underline underline-offset-4  hover:text-[#f7f7f7]">Home</li>
            </Link>
            <Link spy={true} smooth={true} to="About">
              <li className="hover:underline underline-offset-4 hover:text-[#f7f7f7] ">About</li>
            </Link>
            <Link spy={true} smooth={true} to="Skills">
              <li className="hover:underline underline-offset-4 hover:text-[#f7f7f7] ">Skills</li>
            </Link>
            <Link spy={true} smooth={true} to="Project">
              <li className="hover:underline underline-offset-4 hover:text-[#f7f7f7] ">Projects</li>
            </Link>
            <Link spy={true} smooth={true} to="Contact">
              <li className="hover:underline underline-offset-4 hover:text-[#f7f7f7]">Contact</li>
            </Link>
          </ul>
        </div>

        <div className="lg:hidden">
          {click && renderContent()}
        </div>
        <button
          className="block lg:hidden transition ease-in-out duration-700 mb-3"
          onClick={handleClick}
        >
          {click ? (
            <RxCross1
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#f7f7f7",
                borderRadius: "10px",
                color: "#2a2e33",
                padding: "5px",
              }}
            />
          ) : (
            <RxHamburgerMenu
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#f7f7f7",
                borderRadius: "10px",
                color: "#2a2e33",
                padding: "5px",
              }}
            />
          )}
        </button>
      </div>
    </nav>
  );
};

export default Nav;
