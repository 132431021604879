
import nodejsIcon from '../../image/nodejs.png';
import sqlIcon from '../../image/sql-server.png';
import cppIcon from '../../image/c-.png'
import pythonIcon from '../../image/python.png';
import jsIcon from '../../image/JavaScript.png';
import reactIcon from '../../image/science.png';
import tailwindIcon from '../../image/Tailwind CSS.png';
import figmaIcon from '../../image/figma.png';
import githubIcon from '../../image/GitHub.png';

const skills = [
    { name: 'C++', icon: cppIcon },
    { name: 'SQL', icon: sqlIcon },
    { name: 'JavaScript', icon: jsIcon },
    { name: 'Tailwind CSS', icon: tailwindIcon },
    { name: 'Node.js', icon: nodejsIcon },
    { name: 'Python', icon: pythonIcon },
  { name: 'React', icon: reactIcon },
  { name: 'Figma', icon: figmaIcon },
  { name: 'Git/GitHub', icon: githubIcon },
];

const Skills = () => {
    return (
      <div name="Skills" className=" mx-auto p-4 lg:pb-[8rem]">
        <h2 className="text-3xl font-extrabold mb-20 text-center">My Skills</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {skills.map((skill, index) => (
            <div key={`${index}Sk`} className="bg-[#4141416c] hover:scale-105 transition duration-400 ease-in-out hover:bg-[#414141c3] rounded-lg shadow-lg p-6 flex flex-row gap-x-4 items-center justify-center">
              <img src={skill.icon} alt={skill.name} className="w-10 h-10 mb-4" />
              <h3 className="text-xl font-bold">{skill.name}</h3>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default Skills;
