import rightImage from "../../image/coder.svg";
import {FaFacebookSquare}  from "react-icons/fa";
import {FaLinkedin}  from "react-icons/fa";
import {FaGithubSquare } from 'react-icons/fa';


const About = () => {
    return (<div name="About">
        <h2 className="text-center text-4xl font-bold my-8 underline underline-offset-4">About</h2>
         <div className="flex justify-between mb-5 my-8  max-md:flex-col ">
            <section className="w-80% lg:w-[50%] pt-32  ">
                <h2 className="text-2xl font-bold mb-8 underline underline-offset-[8px] text-center  lg:text-start">My Introduction</h2>
                <p className="text-justify" >I am currently pursuing a Master of Computer Applications (MCA) at Lovely Professional University. With a solid foundation in machine learning and web development, I have undertaken several projects in these fields, showcasing my technical skills and dedication to learning. I am always eager to explore new advancements in technology and continually strive to expand my knowledge and expertise. My passion for innovation and commitment to staying updated with the latest trends drives my academic and professional journey </p>
                <div className="my-5 flex gap-x-4">
                    <a href="https://github.com/princeshah01" target="_blank" rel="noopener noreferrer"><FaGithubSquare size={30} className="hover:scale-125 transition duration-400 ease-in-out" /></a>
                    <a href="https://www.linkedin.com/in/princedev/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} className="hover:scale-125 transition duration-400 ease-in-out" /></a>
                    <a href="https://www.facebook.com/people/Prin-Ce/pfbid02y4BXaxJui4XTYHdBDuSgjWS3PHXW7StJc7hhVRCafzn69hQAZqKbSwwr719jeQD8l/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare size={30} className="hover:scale-125 transition duration-400 ease-in-out" /></a>
                </div>
            </section>
            <section className="hidden lg:block w-[50%] py-20 ">

                <img className="hover:scale-110 transition duration-500 ease-in-out" src={rightImage}alt="" />
            </section> 
     

         </div>
    </div>
    );
}

export default About;
