import { Link } from 'react-scroll';
import { FaFacebookSquare, FaLinkedin, FaGithubSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <>


      <div className="flex flex-wrap flex-col items-center md:justify-between md:flex-row lg:flex-row lg:justify-between   border-[#f1f1f1] p-12  pb-20 h-fit  border-y-[1px] ">

        <div className="">
          <h4 className="group text-xl mt-4 mb-4 font-extrabold tracking-widest underline underline-offset-4 ">Links</h4>
          <ul className="text-sm">
            <Link spy={true} smooth={true} to="Home">
              <li className="hover:underline underline-offset-4 font-bold tracking-widest mb-3	 hover:text-[#f7f7f7] text-[16px] ">Home</li>
            </Link>
            <Link spy={true} smooth={true} to="About">
              <li className="hover:underline underline-offset-4 font-bold tracking-widest mb-3 hover:text-[#f7f7f7] text-[16px]">About</li>
            </Link>
            <Link spy={true} smooth={true} to="Skills">
              <li className="hover:underline underline-offset-4 font-bold tracking-widest mb-3 hover:text-[#f7f7f7] text-[16px] ">Skills</li>
            </Link>
            <Link spy={true} smooth={true} to="Project">
              <li className="hover:underline underline-offset-4 font-bold tracking-widest  mb-3 hover:text-[#f7f7f7] text-[16px]">Projects</li>
            </Link>
            <Link spy={true} smooth={true} to="Contact">
              <li className="hover:underline underline-offset-4 font-bold tracking-widest mb-3 hover:text-[#f7f7f7] text-[16px] ">Contact</li>
            </Link>
          </ul>
        </div>
        <div className="text-[16px]">
          <h4 className="group text-xl mt-8 mb-4 font-extrabold tracking-widest underline underline-offset-4">Social</h4>
          <ul>

          <a href="https://github.com/princeshah01" target="_blank" rel="noopener noreferrer" className=" group hover:underline underline-offset-4 flex gap-x-2 font-bold tracking-widest mb-2 "><span><FaGithubSquare size={30} className="group-hover:scale-125 transition duration-400 ease-in-out" /></span><span>Github</span> </a>
          <a href="https://www.linkedin.com/in/princedev/" target="_blank" rel="noopener noreferrer" className="group hover:underline underline-offset-4 group flex gap-x-2 font-bold tracking-widest mb-2" ><span><FaLinkedin size={30} className="group-hover:scale-125 transition duration-400 ease-in-out" /></span><span>Linkedin</span></a>
          <a href="https://www.facebook.com/people/Prin-Ce/pfbid02y4BXaxJui4XTYHdBDuSgjWS3PHXW7StJc7hhVRCafzn69hQAZqKbSwwr719jeQD8l/" className="group hover:underline underline-offset-4 group flex gap-x-2 font-bold  tracking-widest mb-2" target="_blank" rel="noopener noreferrer"><span><FaFacebookSquare size={30} className="group-hover:scale-125 transition duration-400 ease-in-out" /></span><span className='text-[16px]'>Facebook</span></a>
          </ul>
        </div>



      </div>

      <h4 className="text-semibold text-center p-6"> Designed & created by Prince with 💙 </h4>
    </>
  );
}

export default Footer;
