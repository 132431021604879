import Nav from "./components/Navigation/Nav";
import Home from "./components/Hero-section/Home";
import About from "./components/About/About";
import Skills from './components/skills/Skills';
import ContactSection from './components/contact/Contact';
import Footer from './components/footer/Footer';
import { useState, useEffect } from 'react';
import { IoIosArrowUp } from "react-icons/io";

import { animateScroll as scroll } from 'react-scroll';

const App = () => {
  const [showScrollLink, setShowScrollLink] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position > 100) {
      setShowScrollLink(true);
    } else {
      setShowScrollLink(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="bg-[#1c1f1f] text-[#bcd7d7] ">

      <Nav />
      <Home />
      <div className="mx-auto sm:mx-16 max-sm:mx-8 lg:mx-44">

        <About />

        <Skills />
        <ContactSection />


        <Footer />

        {showScrollLink && (
          <button
            onClick={() => scroll.scrollToTop()}
            className=" hidden fixed md:left-[94%] left-[95%] bottom-[10%] lg:block md:block bg-[#333]  text-white p-[10px] rounded-[5px] shadow-lg hover:bg-[#444] transition duration-300"
          >
            <IoIosArrowUp />
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
